
import { defineComponent } from 'vue'
import { useDialpad } from '@/compositions/chats/chats'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import ActionButton from '@/components/shared/templates/ActionButton.vue'
import TmFlag from '@/components/shared/TmFlag.vue'

export default defineComponent({
  components: {
    TmDropdown,
    TmDropdownItem,
    ActionButton,
    TmFlag,
  },
  emits: ['make-call'],
  setup() {
    const { showDialpad } = useDialpad()
    const { isSmMax } = useBreakpoints()

    const callNumbers = [
      {
        country: { id: 'us' },
        phone: '(845) 543-5326',
      },
      {
        country: { id: 'us' },
        phone: '(845) 543-4480',
      },
    ]

    return {
      isSmMax,
      showDialpad,
      callNumbers,
    }
  },
})
