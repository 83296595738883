import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de22db52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ticket-details-header-actions" }
const _hoisted_2 = { class: "primary--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_action_button = _resolveComponent("action-button")!
  const _component_ticket_details_header_call_action = _resolveComponent("ticket-details-header-call-action")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_button, {
      class: "gt-sm",
      size: "large",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:is-solved')))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, {
          name: _ctx.isSolved ? 'refresh' : 'tmi-task-circle',
          left: "",
          class: "primary--text"
        }, null, 8, ["name"]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.isSolved ? 'Reopen' : 'Solve'), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_action_button, {
      class: "lt-md primary--text",
      icon: _ctx.isSolved ? 'refresh' : 'tmi-task-circle',
      text: _ctx.isSolved ? 'Reopen' : 'Solve',
      "mobile-view": "",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:is-solved')))
    }, null, 8, ["icon", "text"]),
    _createVNode(_component_ticket_details_header_call_action, {
      onMakeCall: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('make-call')))
    }),
    _createVNode(_component_action_button, {
      icon: _ctx.isRead ? 'mark_as_unread' : 'drafts',
      text: _ctx.readStateText,
      "mobile-view": _ctx.isSmMax,
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('toggle-read-state')))
    }, null, 8, ["icon", "text", "mobile-view"]),
    _createVNode(_component_action_button, {
      class: "gt-sm",
      icon: "tmi-layers-tasks",
      text: "Create task",
      onClick: _ctx.handleCreateTask
    }, null, 8, ["onClick"]),
    _createVNode(_component_action_button, {
      class: "gt-sm",
      icon: "open_in_new",
      text: "Open in new window",
      onClick: _ctx.openInWindow
    }, null, 8, ["onClick"]),
    _createVNode(_component_action_button, {
      class: "gt-sm",
      icon: _ctx.collapsed ? 'tmi-expand-unfold-xl' : 'tmi-unfold-less-xl',
      text: _ctx.collapsed ? 'Expand all' : 'Collapse all',
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:collapsed', !_ctx.collapsed)))
    }, null, 8, ["icon", "text"]),
    _createVNode(_component_action_button, {
      icon: "tmi-sidebar-right",
      class: "lt-md",
      text: "Details",
      "mobile-view": "",
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:sidebar-visible', true)))
    }),
    _createVNode(_component_tm_dropdown, {
      tooltip: _ctx.isSmMax ? '' : 'More'
    }, {
      menu: _withCtx(() => [
        _createVNode(_component_tm_dropdown_item, {
          class: "lt-md",
          icon: "tmi-layers-tasks",
          label: "Create task",
          onClick: _ctx.handleCreateTask
        }, null, 8, ["onClick"]),
        _createVNode(_component_tm_dropdown_item, {
          icon: _ctx.showEvents ? 'close' : 'history',
          label: _ctx.showEvents ? 'Hide event logs' : 'Show event logs',
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('toggle-show-events')))
        }, null, 8, ["icon", "label"]),
        _createVNode(_component_tm_dropdown_item, {
          icon: "call_merge",
          label: "Merge into another ticket",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openModal('ticketsMerge')))
        }),
        _createVNode(_component_tm_dropdown_item, {
          icon: "search",
          label: "Search this ticket",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('update:search', true)))
        }),
        _createVNode(_component_tm_dropdown_item, {
          icon: "tmi-followers-add",
          label: "Follow",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.followMessage(true)))
        }),
        _createVNode(_component_tm_dropdown_item, {
          icon: "print",
          label: "Print ticket"
        }),
        _createVNode(_component_tm_dropdown_item, {
          icon: "report",
          label: "Mark as spam",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.openMarkAsSpamModal('432543 - Trouble with patient login - urgently need help')))
        }),
        _createVNode(_component_tm_dropdown_item, {
          icon: "delete",
          label: "Delete",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.openDeleteTicketModal('32543 - Trouble with patient login  urgently need help')))
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_action_button, {
          icon: "more_horiz",
          text: "More",
          "hide-tooltip": "",
          "mobile-view": _ctx.isSmMax
        }, null, 8, ["mobile-view"])
      ]),
      _: 1
    }, 8, ["tooltip"]),
    (!_ctx.sidebarVisible)
      ? (_openBlock(), _createBlock(_component_action_button, {
          key: 0,
          icon: "tmi-sidebar-right",
          class: "gt-sm",
          text: "Show sidebar",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('update:sidebar-visible', true)))
        }))
      : _createCommentVNode("", true)
  ]))
}