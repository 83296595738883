
import { defineComponent, computed } from 'vue'
import ActionButton from '@/components/shared/templates/ActionButton.vue'
import TicketDetailsHeaderCallAction from '@/components/pages/tickets/details/ticketHeader/TicketDetailsHeaderCallAction.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { singleWindow } from '@/services/singleWindow'
import { useModals } from '@/compositions/modals'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmButton,
    TicketDetailsHeaderCallAction,
    TmDropdown,
    TmDropdownItem,
    ActionButton,
  },
  props: {
    showEvents: {
      type: Boolean,
    },
    isSolved: {
      type: Boolean,
    },
    collapsed: {
      type: Boolean,
    },
    sidebarVisible: {
      type: Boolean,
    },
    isRead: {
      type: Boolean,
    },
  },
  emits: ['toggle-read-state', 'update:collapsed', 'update:sidebar-visible', 'update:search', 'update:is-solved', 'toggle-show-events', 'make-call'],
  setup(props) {
    const { isSmMax } = useBreakpoints()
    const { openModal } = useModals()
    const { openMarkAsSpamModal, openDeleteTicketModal, followMessage } = useTicketsOpenModals()

    const openInWindow = () => {
      singleWindow(1024, 600, '/ticket-details-window')
    }

    const handleCreateTask = () => {
      openModal('tasks', {
        description: 'Related to the ticket 324354 “Trouble with patient login - urgently need help”',
      })
    }

    const readStateText = computed(() => {
      if (props.isRead) {
        return isSmMax.value ? 'Unread' : 'Mark as unread'
      }
      return isSmMax.value ? 'Read' : 'Mark as read'
    })

    return {
      readStateText,
      openInWindow,
      openMarkAsSpamModal,
      openDeleteTicketModal,
      openModal,
      handleCreateTask,
      isSmMax,
      followMessage,
    }
  },
})
