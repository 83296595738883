
import { defineComponent, ref, computed } from 'vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { TicketsStatusName } from '@/definitions/_general/_types/optionsList'
import type { StatusType } from '@/definitions/shared/types'
import { ticketsStatusOptions } from '@/definitions/_general/_data/optionsList'
import { useModals } from '@/compositions/modals'
import TicketDetailsHeaderActions from '@/components/pages/tickets/details/ticketHeader/TicketDetailsHeaderActions.vue'
import TicketDetailsSearch from '@/components/pages/tickets/details/ticketHeader/TicketDetailsSearch.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmEditableText from '@/components/shared/TmEditableText.vue'

export default defineComponent({
  components: {
    TicketDetailsSearch,
    TicketDetailsHeaderActions,
    TmEditableText,
    TmStatus,
  },
  props: {
    hide: {
      type: Boolean,
    },
    isRead: {
      type: Boolean,
    },
    sidebarVisible: {
      type: Boolean,
    },
    collapsed: {
      type: Boolean,
    },
    isSimulateTicket: {
      type: Boolean,
    },
    search: {
      type: String,
    },
    showEvents: {
      type: Boolean,
    },
    isDraft: {
      type: Boolean,
    },
    isSolved: {
      type: Boolean,
    },
  },
  emits: ['update:sidebar-visible', 'update:search', 'update:collapsed', 'toggle-read-state', 'toggle-show-events', 'make-call', 'update:is-solved'],
  setup(props, { emit }) {
    const { isSmMax } = useBreakpoints()
    const { openModal } = useModals()
    const showSearch = ref(false)
    const computedStatus = computed(() => ticketsStatusOptions.find((e: StatusType<TicketsStatusName>) => props.isDraft
      ? e.name === 'draft'
      : props.isSolved
        ? e.name === 'solved'
        : e.name === 'new')
    )
    const computedSubject = computed(() => {
      if (props.isDraft) { return 'Test ticket for me' }
      if (props.isSimulateTicket) { return 'This is your demo ticket' }
      return 'Trouble with patient login - urgently need help'
    })

    const hideSearch = () => {
      showSearch.value = false
      emit('update:search', '')
    }

    return {
      hideSearch,
      computedSubject,
      showSearch,
      computedStatus,
      openModal,
      isSmMax,
    }
  },
})
