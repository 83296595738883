
import { defineComponent, ref } from 'vue'
import TicketMessage from '@/components/pages/tickets/details/TicketMessage.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useModals } from '@/compositions/modals'
import TmAudioPlayer from '@/components/shared/TmAudioPlayer.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TicketCallTranscript from '@/components/pages/tickets/details/TicketCallTranscript.vue'

export default defineComponent({
  components: {
    TicketCallTranscript,
    TmButton,
    TmAudioPlayer,
    TicketMessage,
    TmDropdownItem,
  },
  props: {
    collapsed: {
      type: Boolean,
    },
  },
  setup() {
    const { openModal } = useModals()
    const isDeleted = ref(false)
    const transcriptVisible = ref(false)

    const handleCreateTask = () => {
      openModal('tasks', {
        description: 'Related to the ticket 324354 “Trouble with patient login - urgently need help”\nRelated message:\n”Started investigation on patient login errors reported...”\nRead more',
      })
    }

    return {
      transcriptVisible,
      isDeleted,
      handleCreateTask,
    }
  },
})
