
import { computed, defineComponent, markRaw, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import { editorBtns } from '@/definitions/shared/editor/data'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { ticketsStatusOptions } from '@/definitions/_general/_data/optionsList'
import MessageEditorNoteToggleButton from '@/components/shared/editor/MessageEditorNoteToggleButton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import { openFileUpload } from '@/services/file'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import TmTooltipDropdown from '@/components/shared/TmTooltipDropdown.vue'
import { capitalizeFirstLetter } from '@/services/string'

export default defineComponent({
  components: {
    TmTooltipDropdown,
    TmStylizedText,
    TmButton,
    TmDropdown,
    TmDropdownItem,
    TmStatus,
  },
  props: {
    isEditorCollapsed: {
      type: Boolean,
    },
    showCoworkers: {
      type: Boolean,
    },
  },
  emits: ['btn-click', 'focus', 'submit', 'toggle-editor-state'],
  setup() {
    const { isMdMax, isSmMax } = useBreakpoints()
    const noteState = ref(false)

    const submitDropdownState = ref(false)
    const ticketUpdateOptions = [
      'Close tab',
      'Stay on ticket',
      'Next ticket in the view',
    ]
    const ticketUpdateBtnText = ref('Stay on ticket')

    const editor = ref('')
    const submitAsText = ref('Pending')
    const editorButtonsFormatted = computed<EditorButtonType[]>(() => !noteState.value
      ? [
          editorBtns.text,
          editorBtns.emoji,
          {
            ...editorBtns.attachment,
            component: null,
            clickEvent: () => openFileUpload(),
          },
          editorBtns.assignment,
          {
            ...editorBtns.tags,
            showInDropdown: isMdMax.value,
          },
          {
            ...editorBtns.ai,
            showInDropdown: isSmMax.value,
          },
          {
            ...editorBtns.image,
            showInDropdown: true,
          },
          {
            ...editorBtns.mention,
            showInDropdown: true,
          },
          {
            ...editorBtns.signature,
            showInDropdown: true,
          },
          {
            ...editorBtns.note,
            permanent: true,
            isActive: noteState.value,
            component: markRaw(MessageEditorNoteToggleButton),
          },
        ]
      : [
          editorBtns.text,
          editorBtns.emoji,
          {
            ...editorBtns.attachment,
            clickEvent: openFileUpload,
          },
          {
            ...editorBtns.tags,
            showInDropdown: isMdMax.value,
          },
          {
            ...editorBtns.image,
            showInDropdown: isMdMax.value,
          },
          editorBtns.mention,
          {
            ...editorBtns.note,
            permanent: true,
            isActive: noteState.value,
            component: markRaw(MessageEditorNoteToggleButton),
          },
        ])

    const computedTooltipText = computed(() => {
      if (!editor.value) {
        return [
          'Status will be updated to ',
          {
            text: 'Pending',
            style: 'semi-bold',
          },
        ]
      }

      return [
        noteState.value ? 'An ' : 'A ',
        {
          text: noteState.value ? 'internal note' : 'public reply',
          style: 'semi-bold',
        },
        noteState.value ? ' will be added' : ' will be sent',
        ', and status will be updated to ',
        {
          text: 'Pending',
          style: 'semi-bold',
        },
      ]
    })

    return {
      submitDropdownState,
      computedTooltipText,
      editor,
      editorButtonsFormatted,
      ticketUpdateOptions,
      ticketsStatusOptions,
      ticketUpdateBtnText,
      submitAsText,
      capitalizeFirstLetter,
      noteState,
      isSmMax,
    }
  },
})
