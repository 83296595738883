
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<EditorButtonType>,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    return {
      isSmMax,
    }
  },
})
