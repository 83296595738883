
import { defineComponent, nextTick, computed, onMounted, ref } from 'vue'
import TicketDetailsHeader from '@/components/pages/tickets/details/TicketDetailsHeader.vue'
import TicketDetailsEditor from '@/components/pages/tickets/details/TicketDetailsEditor.vue'
import TicketMessage from '@/components/pages/tickets/details/TicketMessage.vue'
import TicketCall from '@/components/pages/tickets/details/TicketCall.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { getTableData } from '@/services/tableService'
import type { TicketMessageType, TableMessageType } from '@/definitions/tickets/types'
import { notify } from '@kyvg/vue3-notification'
import { newMessage, simulateTicketMessage, ticketMessageCallItem } from '@/definitions/tickets/data'
import { checkMatch } from '@/services/string'
import SearchNoResults from '@/components/shared/search/SearchNoResults.vue'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'

export default defineComponent({
  components: {
    SearchNoResults,
    TmScrollbar,
    TicketMessage,
    TicketCall,
    TicketDetailsEditor,
    TicketDetailsHeader,
  },
  props: {
    isSimulateTicket: {
      type: Boolean,
    },
    sidebarVisible: {
      type: Boolean,
    },
    isDraft: {
      type: Boolean,
    },
    showCoworkers: {
      type: Boolean,
    },
    editorState: {
      type: Boolean,
    },
    isSolved: {
      type: Boolean,
    },
  },
  emits: ['toggle-editor-state', 'update:sidebar-visible', 'update:is-solved'],
  setup(props, { emit }) {
    const { markUnreadMessage, markReadMessage } = useTicketsOpenModals()
    const isRead = ref(true)
    const showEvents = ref(false)
    const search = ref()
    const showTransition = ref(false)
    const messageItems = ref<TableMessageType[]>(getTableData('ticketDetails'))
    const prevScrollPosition = ref(0)
    const mobileHeaderHide = ref(false)
    const messageItemsComputed = computed(() => {
      if (props.isSimulateTicket) {
        return [simulateTicketMessage]
      }
      if (search.value) {
        return messageItems.value.filter((e: TableMessageType) => checkMatch(e.messageData.text, search.value))
      }
      return messageItems.value
    })

    const isMessagesCollapsed = computed(() => messageItemsComputed.value.every((e: TableMessageType) => e.messageData.collapsed))

    const toggleMessagesCollapse = () => {
      const collapsedValue = !isMessagesCollapsed.value

      messageItems.value.forEach((e: TableMessageType) => {
        e.messageData.collapsed = collapsedValue
      })
    }

    const scrollbarRef = ref()

    const updateScrollbar = (value: number) => {
      nextTick(() => {
        scrollbarRef.value.$el.scrollTop = value
      })
    }

    const setScrollbarOnStart = () => {
      if (scrollbarRef.value) {
        updateScrollbar(scrollbarRef.value.$el.scrollHeight)
      }
    }

    onMounted(() => {
      setScrollbarOnStart()
      setTimeout(() => {
        showTransition.value = true
      }, 1)
    })

    const toggleReadState = () => {
      if (isRead.value) {
        const length = messageItems.value.length
        messageItems.value[length - 1].messageData.unread = true

        markUnreadMessage(true)
      } else {
        messageItems.value = messageItems.value.map((e: TableMessageType) => ({
          ...e,
          messageData: {
            ...e.messageData,
            unread: false,
          },
        }))
        markReadMessage(true)
      }

      isRead.value = !isRead.value
    }

    const handleSendMessage = () => {
      messageItems.value.push({
        id: 'new-message-id',
        type: 'message',
        messageData: newMessage,
      })

      notify({ text: 'Your message has been successfully sent. You can still undo this action during 10 seconds.', type: 'success' })
      setScrollbarOnStart()
    }
    const handleLastMessage = () => {
      setScrollbarOnStart()
      emit('toggle-editor-state', false)
    }

    const unsendMessage = () => {
      messageItems.value = messageItems.value.filter((e: TableMessageType) => !e.messageData.unsend)
    }

    const updateMessageItem = (id: string, item: Partial<TicketMessageType>) => {
      messageItems.value = messageItems.value.map((e: TableMessageType) => {
        if (id !== e.id) { return e }

        return {
          ...e,
          messageData: {
            ...e.messageData,
            ...item,
          },
        }
      })
    }
    const makeCall = () => {
      messageItems.value.push({
        id: 'call',
        type: 'call',
        messageData: ticketMessageCallItem,
      })
    }

    const onScroll = (position: { up: number, down: number, left: number, right: number }) => {
      if (!showTransition.value) { return }
      mobileHeaderHide.value = prevScrollPosition.value >= position.up
      prevScrollPosition.value = position.up
    }

    return {
      onScroll,
      updateMessageItem,
      handleLastMessage,
      handleSendMessage,
      mobileHeaderHide,
      messageItemsComputed,
      unsendMessage,
      showEvents,
      setScrollbarOnStart,
      toggleReadState,
      scrollbarRef,
      messageItems,
      search,
      isMessagesCollapsed,
      makeCall,
      isRead,
      toggleMessagesCollapse,
    }
  },
})
