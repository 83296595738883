import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createBlock(_component_tm_field, {
    "model-value": _ctx.item.isActive,
    type: "toggle",
    disable: _ctx.disable
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.isSmMax ? 'Note' : 'Internal note'), 1)
    ]),
    _: 1
  }, 8, ["model-value", "disable"]))
}