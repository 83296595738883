
import { defineComponent } from 'vue'
import { ticketCallTranscript } from '@/definitions/tickets/data'

export default defineComponent({
  setup() {
    return {
      ticketCallTranscript,
    }
  },
})
