import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be3c2572"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ticket-call-transcript" }
const _hoisted_2 = { class: "ticket-call-transcript__time" }
const _hoisted_3 = { class: "ticket-call-transcript__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticketCallTranscript, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "ticket-call-transcript__item"
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.time), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["ticket-call-transcript__person", {
          'ticket-call-transcript__person--agent': item.isAgent,
        }])
        }, _toDisplayString(item.person), 3),
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.text), 1)
      ]))
    }), 128))
  ]))
}