import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42919d64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ticket-details-main" }
const _hoisted_2 = { class: "ticket-details-main__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ticket_details_header = _resolveComponent("ticket-details-header")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!
  const _component_search_no_results = _resolveComponent("search-no-results")!
  const _component_ticket_details_editor = _resolveComponent("ticket-details-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ticket_details_header, {
      search: _ctx.search,
      "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      "is-solved": _ctx.isSolved,
      collapsed: _ctx.isMessagesCollapsed,
      "sidebar-visible": _ctx.sidebarVisible,
      "is-draft": _ctx.isDraft,
      "is-simulate-ticket": _ctx.isSimulateTicket,
      "is-read": _ctx.isRead,
      "show-events": _ctx.showEvents,
      hide: _ctx.mobileHeaderHide,
      onToggleShowEvents: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showEvents = !_ctx.showEvents)),
      onToggleReadState: _ctx.toggleReadState,
      onMakeCall: _ctx.makeCall,
      "onUpdate:sidebarVisible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:sidebar-visible', $event))),
      "onUpdate:collapsed": _ctx.toggleMessagesCollapse,
      "onUpdate:isSolved": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:is-solved', $event)))
    }, null, 8, ["search", "is-solved", "collapsed", "sidebar-visible", "is-draft", "is-simulate-ticket", "is-read", "show-events", "hide", "onToggleReadState", "onMakeCall", "onUpdate:collapsed"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.messageItemsComputed.length && !_ctx.isDraft)
        ? (_openBlock(), _createBlock(_component_tm_scrollbar, {
            key: 0,
            ref: "scrollbarRef",
            visible: "",
            onOnScroll: _ctx.onScroll
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageItemsComputed, (item, i) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent(item.type === 'call' ? 'ticket-call' : 'ticket-message'), _mergeProps({ key: i }, item.messageData, {
                  class: "mr-2",
                  search: _ctx.search,
                  "show-events": _ctx.showEvents,
                  onToggleCollapse: ($event: any) => (_ctx.updateMessageItem(item.id, { collapsed: !item.messageData.collapsed })),
                  onUnsendMessage: _ctx.unsendMessage
                }), null, 16, ["search", "show-events", "onToggleCollapse", "onUnsendMessage"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["onOnScroll"]))
        : (_openBlock(), _createBlock(_component_search_no_results, {
            key: 1,
            icon: "library_books",
            title: _ctx.isDraft ? 'There are no messages yet' : 'No search results',
            class: "pa-0 h100pr",
            text: _ctx.isDraft ? 'Here you’ll find a complete history of the ticket messages including internal notes.' : 'Please try a different search keyword'
          }, null, 8, ["title", "text"]))
    ]),
    _createVNode(_component_ticket_details_editor, {
      "show-coworkers": _ctx.showCoworkers,
      "is-editor-collapsed": _ctx.editorState,
      onBtnClick: _ctx.handleLastMessage,
      onSubmit: _ctx.handleSendMessage,
      onToggleEditorState: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('toggle-editor-state')))
    }, null, 8, ["show-coworkers", "is-editor-collapsed", "onBtnClick", "onSubmit"])
  ]))
}